import React, { useContext, useEffect, useState, Fragment } from "react";
import CookiePreferences from "./CookiePreferences";
import close from "../../assets/img/icons/close.svg";
import {
  GlobalStateContext,
  GlobalDispatchContext,
  ACTIONS,
} from "../../context/GlobalContextProvider";
import { Link } from "gatsby";

const CookieConsent = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const [hideMe, setHideMe] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      if (!state.acceptCookie && !state.showPageLoading) {
        setHideMe(false);
      }
    }, 3000);
  }, [state.acceptCookie, state.showPageLoading]);

  const handleAccept = () => {
    setHideMe(true);
    dispatch({ type: ACTIONS.SET_COOKIE_CONSENT });
  };

  return (
    <Fragment>
      <div className={`cookie-consent-container ${!hideMe ? "showme" : ""}`}>
        <div className='wrapper'>
          <img
            className='close'
            src={close}
            alt='Close Cookie Consent'
            aria-hidden
            onClick={() => setHideMe(true)}
          />
          <p className='no-mar'>
            We use cookies to improve user experience, and analyze website
            traffic. For these reasons, we may share your site usage data with
            our analytics partners. By clicking "Accept Cookies", you consent to
            store on your device all the technologies described in our{" "}
            <Link to='/cookie-policy'>Cookie Policy</Link>. You can change your
            cookies settings at any time by clicking "Cookie Preferences".
          </p>
          <div className='button-container'>
            <button className='secondary' onClick={handleAccept}>
              Accept
            </button>
            <button
              className='primary'
              onClick={() =>
                dispatch({ type: ACTIONS.SHOW_COOKIE_PREFERENCES })
              }
            >
              Preferences
            </button>
          </div>
        </div>
      </div>
      {state.showCookiePreferences && (
        <CookiePreferences
          handleClose={() =>
            dispatch({ type: ACTIONS.SHOW_COOKIE_PREFERENCES })
          }
        />
      )}
    </Fragment>
  );
};

export default CookieConsent;
