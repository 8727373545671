import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import AOS from "aos";

import Header from "./header";
import Footer from "./footer";

const Layout = ({ children, contactPage, headerTransparent, isHome=true }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [footerHeight, setFooterHeight] = React.useState("");

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      setFooterHeight(document.querySelector(".main-site-footer").clientHeight);
    }
  }, [footerHeight]);

  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <React.Fragment>
    
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        headerTransparent={headerTransparent}
        isHome={isHome}
      />
      <main className='main-body-container'>{children}</main>
      <Footer contactPage={contactPage} />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
