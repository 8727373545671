import React, { Fragment, useEffect, useRef, useState, useContext } from "react";
import { TweenMax, Power3 } from "gsap";
import {isMobile} from 'react-device-detect'
import loadingIcon from "../../assets/video/page-loader-opt2.gif";
// import loadingIcon from "../../assets/img/icons/loader_01.gif";
// import logoIcon from "../../assets/img/virsat-logo.svg";
import background from "../../assets/img/bg/virsat-loading-page-background.jpg";
import backgroundMobile from "../../assets/img/bg/virsat-loading-page-background-mobile.jpg";
// import {StaticImage} from 'gatsby-plugin-image'
import {
  GlobalDispatchContext,
  ACTIONS,
} from "../../context/GlobalContextProvider";

const PageLoading = ({ isHome = false, showLoading=true }) => {
  const dispatch = useContext(GlobalDispatchContext);
  // let logo = useRef(null);
  // let loading = useRef(null);
  let container = useRef(null);
  const [showPageLoading, setShowPageLoading] = useState(true);

  // useEffect(() => {
  //   // TweenMax.to(logo, 0.5, { css: { opacity: 1 }, ease: Power3.easeInse });
  //   TweenMax.to(loading, 0.5, {
  //     css: { opacity: 1 },
  //     ease: Power3.easeInse,
  //     // delay: 0.5,
  //   });
  // }, []);

  useEffect(() => {
    window.addEventListener("domcontentloaded", updatePageLoading());

    return () =>
      window.removeEventListener("domcontentloaded", setShowPageLoading(false));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPageLoading]);

  const updatePageLoading = () => {
    setTimeout(() => {
      setShowPageLoading(false);
      if (isHome) {
        TweenMax.to(container, 0.8, {
          x: "-100%",
          ease: Power3.easeOut,
        });
      } else {
        TweenMax.to(container, 0.8, {
          opacity: 0,
          ease: Power3.easeOut,
        });
      }
    }, 3000);
    setTimeout(() => {
      dispatch({ type: ACTIONS.SHOW_PAGE_LOADING });
    }, 4500);
  };

  return (
    <Fragment>
      {/* <div className={`page-loading-container ${isHome ? '':'blend-mode'}`} ref={(el) => (container = el)}>
        <div className='wrapper'> */}
          {/* <img
            className='logo'
            src={logoIcon}
            ref={(el) => (logo = el)}
            alt='VIRSAT Logo'
          /> */}
          {/* <img
            className='loading'
            ref={(el) => (loading = el)}
            src={loadingIcon}
            alt='Loading...'
          /> */}
        {/* </div>
      </div> */}
      <div className={`virsat-loading-page-background ${!showLoading && 'hide-me'}`} ref={(el) => (container = el)}>
        {/* {isMobile ? (
          <StaticImage
            src='../../assets/img/bg/virsat-loading-page-background-mobile.jpg'
            alt='VIRSAT Newsletter Background'
            placeholder='blurred'
            objectFit
            quality="100"
            style={{height:'100%', width:'100%'}}
          />
        ):(
          <StaticImage
            src='../../assets/img/bg/virsat-loading-page-background.jpg'
            alt='VIRSAT Newsletter Background'
            placeholder='blurred'
            objectFit
            quality="100"
            style={{height:'100%', width:'100%'}}
          />
        )} */}

        <img src={isMobile ? backgroundMobile : background} alt="VIRSAT Newsletter Background"/>
      </div>
      <img
        className={`loading-icon ${!showLoading && 'hide-me'}`}
        // ref={(el) => (loading = el)}

        src={loadingIcon}
        alt='Loading...'
      />
    </Fragment>
  );
};

export default PageLoading;
