import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { Link } from "gatsby";
import logo from "../../assets/img/virsat-logo.svg";
import logoLIght from "../../assets/img/virsat-logo-light.svg";
import arrow from "../../assets/img/icons/arrow.svg";
import arrowLight from "../../assets/img/icons/arrow-light.svg";
import mobileNav from "../../assets/img/icons/mobile-nav.svg";
import mobileNavActive from "../../assets/img/icons/mobile-nav-active.svg";
import mobileNavClose from "../../assets/img/icons/mobile-nav-close.svg";
import PageLoading from "../common/PageLoading";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import { TweenLite, Power3 } from "gsap";

const Header = ({ headerTransparent, isHome }) => {
  const state = useContext(GlobalStateContext);
  const [offset, setOffset] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  let logoRef = useRef(null);
  let navLinksRef = useRef(null);
  let mobileMenu = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", updateOffset);
    if (offset > window.innerHeight / 4) {
      document.querySelector(".move-to-top").classList.add("hideme");
    } else {
      document.querySelector(".move-to-top").classList.remove("hideme");
    }
    return () => window.removeEventListener("scroll", updateOffset);
  }, [offset]);

  useEffect(() => {
    updateOffset(window.pageYOffset);
  }, []);

  useEffect(() => {
    if (!state.showPageLoading) {
      TweenLite.to(logoRef, 1, { y: 0, opacity: 1, ease: Power3.easeInOut });
      TweenLite.to(navLinksRef, 1, {
        y: 0,
        opacity: 1,
        ease: Power3.easeInOut,
      });
      TweenLite.to(mobileMenu, 1, { y: 0, opacity: 1, ease: Power3.easeInOut });
    }
  }, [state.showPageLoading]);

  const updateOffset = () => {
    setOffset(window.pageYOffset);
  };

  const activeStyle = {
    color: "#d9a728",
    borderBottom: "3px solid #d9a728",
    paddingBottom: "15px",
    fontWeight: "900",
  };

  // const activeStyleSub = {
  //   color: "#d9a728",
  //   fontWeight: "900",
  // };

  return (
    <Fragment>
      {/* {state.showPageLoading && <PageLoading isHome={isHome} />} */}
      <PageLoading isHome={isHome} showLoading={state.showPageLoading} />

      <div id='top' />
      <header
        className={`site-header ${offset > 90 && "fixed"} ${
          !headerTransparent && "not-home"
        }`}
      >
        <nav className='site-navigation'>
          <div className='site-logo' ref={(el) => (logoRef = el)}>
            <Link to='/'>
              <img src={offset > 90 ? logo : logoLIght} alt='VIRSAT Logo' />
            </Link>
          </div>
          <div
            className={`nav-links ${showMenu ? "showme" : ""}`}
            ref={(el) => (navLinksRef = el)}
          >
            <ul className='nav-links-ul'>
              <li className='nav-link-li'>
                <Link
                  className='nav-link-li-a'
                  activeStyle={activeStyle}
                  to='/'
                >
                  Home
                </Link>
              </li>
              <li className='nav-link-li'>
                <Link
                  className='nav-link-li-a'
                  activeStyle={activeStyle}
                  to='/about/'
                >
                  About
                </Link>
              </li>
              <li className='nav-link-li'>
                <Link
                  className='nav-link-li-a'
                  activeStyle={activeStyle}
                  getProps={({ isPartiallyCurrent }) => {
                    return isPartiallyCurrent
                      ? { className: "nav-link-li-a active" }
                      : { className: "nav-link-li-a" };
                  }}
                  to='/articles/'
                >
                  Articles
                </Link>
              </li>
              {/* <li className='nav-link-li'>
                <Link
                  className='nav-link-li-a'
                  activeStyle={activeStyle}
                  getProps={({ isPartiallyCurrent }) => {
                    return isPartiallyCurrent
                      ? { className: "nav-link-li-a active" }
                      : { className: "nav-link-li-a" };
                  }}
                  to='/news/'
                >
                  Latest News
                </Link>
              </li> */}
              <li className='nav-link-li'>
                <Link
                  className='nav-link-li-a'
                  activeStyle={activeStyle}
                  to='/animations/'
                >
                  Animations
                </Link>
              </li>
              <li className='nav-link-li'>
                <Link
                  to='/products/'
                  activeStyle={activeStyle}
                  getProps={({ isPartiallyCurrent }) => {
                    return isPartiallyCurrent
                      ? { className: "nav-link-li-a with-sub active" }
                      : { className: "nav-link-li-a with-sub" };
                  }}
                >
                  Products
                  <img
                    className='arrow'
                    src={offset > 90 ? arrow : arrowLight}
                    alt='Arrow'
                  />
                </Link>
                <div className={`nav-submenu products`}>
                  <div className='nav-submenu-container'>
                    <ul className='nav-submenu-ul first'>
                      <li className='nav-submenu-li'>
                        <Link
                          to='/news/virtual-reality-driving-simulator/'
                          className='nav-submenu-li-a'
                        >
                          VR Driving Simulator
                        </Link>
                      </li>
                      <li className='nav-submenu-li'>
                        <Link
                          to='/products/?cat=Animations'
                          className='nav-submenu-li-a'
                        >
                          Animations
                        </Link>
                      </li>
                      <li className='nav-submenu-li'>
                        <Link
                          to='/products/?cat=Apps'
                          className='nav-submenu-li-a'
                        >
                          Apps
                        </Link>
                      </li>
                      <li className='nav-submenu-li'>
                        <Link
                          to='/products/?cat=Culture Change'
                          className='nav-submenu-li-a'
                        >
                          Culture Change
                        </Link>
                      </li>
                      
                    </ul>
                    <ul className='nav-submenu-ul'>
                      <li className='nav-submenu-li'>
                        <Link
                          to='/products/?cat=Learning from Incidents'
                          className='nav-submenu-li-a'
                        >
                          Learning from Incidents
                        </Link>
                      </li>
                      <li className='nav-submenu-li'>
                        <Link
                          to='/products/?cat=Life Saving Rules'
                          className='nav-submenu-li-a'
                        >
                          Life Saving Rules
                        </Link>
                      </li>
                      <li className='nav-submenu-li'>
                        <Link
                          to='/products/?cat=Training'
                          className='nav-submenu-li-a'
                        >
                          Training
                        </Link>
                      </li>
                      <li className='nav-submenu-li'>
                        <Link
                          to='/products/?cat=Games'
                          className='nav-submenu-li-a'
                        >
                          Games
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className='nav-link-li'>
                <Link
                  className='nav-link-li-a contact'
                  // className='nav-link-li-a with-sub'
                  to='/contact/'
                  activeStyle={activeStyle}
                >
                  Contact
                  {/* <img
                    className='arrow'
                    src={offset > 90 ? arrow : arrowLight}
                    alt='Arrow'
                  /> */}
                </Link>
                {/* <div className={`nav-submenu contact`}>
                  <div className='nav-submenu-container'>
                    <ul className='nav-submenu-ul'>
                    <li className='nav-submenu-li'>
                      <Link
                        to='/contact'
                        className='nav-submenu-li-a'
                        activeStyle={activeStyleSub}
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li className='nav-submenu-li'>
                      <Link
                        to='/partner'
                        className='nav-submenu-li-a'
                        activeStyle={activeStyleSub}
                      >
                        Become Our Partner
                      </Link>
                    </li>
                    </ul>
                  </div>
                </div> */}
              </li>
              {/* <li className='nav-link-li become-parter'>
                <Link
                  className='nav-link-li-a'
                  activeStyle={activeStyle}
                  to='/partner'
                >
                  Become Our Partner
                </Link>
              </li> */}
            </ul>
          </div>
          <div className='nav-hamburger' ref={(el) => (mobileMenu = el)}>
            {showMenu ? (
              <img
                src={mobileNavClose}
                alt='Mobile Navigation Close'
                onClick={() => setShowMenu(false)}
                aria-hidden
              />
            ) : (
              <img
                src={offset > 90 ? mobileNavActive : mobileNav}
                alt='Mobile Navigation Show'
                onClick={() => setShowMenu(true)}
                aria-hidden
              />
            )}
          </div>
        </nav>
      </header>
    </Fragment>
  );
};

export default Header;
