import React, { useState, useContext, useEffect } from "react";
import close from "../../assets/img/icons/close.svg";
import {
  GlobalStateContext,
  GlobalDispatchContext,
  ACTIONS,
} from "../../context/GlobalContextProvider";

const CookiePreferences = ({ handleClose }) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const [activeLi, setActiveLi] = useState("");
  const [checkbox, setCheckbox] = useState(
    state.enableAnalytics === "true" || state.enableAnalytics === true
      ? true
      : false
  );

  const handleClickLi = (li) => {
    if (li === activeLi) {
      setActiveLi("");
    } else {
      setActiveLi(li);
    }
  };

  const handleCheckbox = () => {
    setCheckbox(!checkbox);
  };

  useEffect(() => {
    dispatch({ type: ACTIONS.SET_GOOGLE_ANALYTICS, payload: checkbox });
  }, [checkbox, dispatch]);

  return (
    <div className='cookie-preferences-container'>
      <div className='wrapper' data-aos='zoom-in'>
        <img
          src={close}
          className='close'
          alt='CLose Cookie Preferences'
          aria-hidden
          onClick={handleClose}
        />
        <div className='main-subtitle'>Your Cookie Preferences</div>
        <p>
          This website uses cookies to improve your experience while you
          navigate through the website. These cookies will be stored in your
          browser only with your consent. You also have the option to opt-out of
          these cookies. But opting out of some of these cookies may have an
          effect on your browsing experience. You can learn more about how we
          use cookies by visiting our Cookie Policy and Privacy Policy.
        </p>

        <ul className='cookie-ul'>
          <li className='cookie-li first'>
            <header aria-hidden onClick={() => handleClickLi("necessary")}>
              <div className='left'>
                <div
                  className={`site-arrow ${
                    activeLi === "necessary" ? "right" : "down"
                  }`}
                ></div>
                <span>Necessary</span>
              </div>
              <div className='right'>
                <span>Always Enabled</span>
              </div>
            </header>
            <div className={`body ${activeLi === "necessary" && "showme"}`}>
              <p className='no-mar'>
                Necessary cookies are absolutely essential for the website to
                function properly. This category only includes cookies that
                ensures basic functionalities and security features of the
                website. These cookies do not store any personal information.
              </p>
            </div>
          </li>
          <li className='cookie-li'>
            <header aria-hidden onClick={() => handleClickLi("statistics")}>
              <div className='left'>
                <div
                  className={`site-arrow ${
                    activeLi === "statistics" ? "right" : "down"
                  }`}
                ></div>
                <span>Statistics</span>
              </div>
              <div className='right'>
                <span>Always Enabled</span>
              </div>
              {/* <div className='right'>
                <div className='checkbox-container'>
                  <span id='checkboxLabel'>
                    {checkbox ? "Enabled" : "Disabled"}
                  </span>
                  <label className='checkbox' htmlFor='enable_analytics'>
                    <input
                      type='checkbox'
                      checked={checkbox}
                      name='enable_analytics'
                      id='enable_analytics'
                      onChange={handleCheckbox}
                    />
                    <div className='checkbox-bg'></div>
                    <i className='indicator'></i>
                  </label>
                </div>
              </div> */}
            </header>
            <div className={`body ${activeLi === "statistics" && "showme"}`}>
              <p className='no-mar'>
                Analytics cookies monitor how users reached the Site, and how
                they interact with and move around once on the Site. These
                cookies let us know what features on the Site are working the
                best and what features on the Site can be improved. &nbsp;
                <a href="javascript:gaOptout();">Deactivate Google Tracking</a>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CookiePreferences;
